/* global angular */

var downloadjs = require('downloadjs')
require('../../directives/bind-html-compile.directive.js')
require('../ext-wcm-image/ext-wcm-image.component.js')
var extContentService = require('../../services/ext-content.service.js').extContentService

angular.module('ExtWcmHtmlContentCmp', ['bindHtmlCompileCmp', 'ExtWcmImageCmp'])
  .factory('extContentService', extContentService)
  .component('extWcmHtmlContent', {
    template: require('./ext-wcm-html-content.component.tpl.html'),
    controller: ExtWcmHtmlContentCtrl,
    controllerAs: 'vm',
    bindings: {
      fileContent: '<',
      wcmHrefContext: '<'
    }
  })

ExtWcmHtmlContentCtrl.$inject = ['extContentService']

function ExtWcmHtmlContentCtrl (extContentService) {
  var vm = this

  vm.$onInit = function () {
    vm.download = download
    vm.content = new FileContent(vm.fileContent).getInnerBody().replaceImgs().modifyAnchors().toString()
  }

  vm.$onChanges = function (changesObj) {
    if (changesObj.fileContent) {
      vm.content = new FileContent(vm.fileContent).getInnerBody().replaceImgs().modifyAnchors().toString()
    }
  }

  function download (objectName) {
    extContentService.getBlob('internet', objectName).promise
      .then(function (res) {
        var mimeType = 'application/' + objectName.split('.').reverse()[0]
        downloadjs(res.data, objectName, mimeType)
      })
  }

  function FileContent (fileContent) {
    this.fileContent = fileContent

    this.toString = function () {
      return this.fileContent
    }

    this.getInnerBody = function () {
      var re = /<body[^>]*>((.|[\n\r])*)<\/body>/im
      var content = this.fileContent && re.exec(this.fileContent)[1]
      return new FileContent(content)
    }

    this.replaceImgs = function () {
      var content = this.fileContent && this.fileContent.replace(/<img.*?>/gmi, function (imgTag) {
        var re = /src="(.*?)"/g
        var objectName = re.exec(imgTag)[1]
        return '<ext-wcm-image portal="internet" object-name="' + objectName + '"></ext-wcm-image>'
      })
      return new FileContent(content)
    }

    this.modifyAnchors = function () {
      var content = this.fileContent && this.fileContent.replace(/<a(.*?)>/gmi, function (aTagFirstOfPair, aTagFirstOfPairContent) {
        var anchorAttributes = new AnchorAttributes(aTagFirstOfPairContent.split(' '))
          .convertToWcmLink(vm.wcmHrefContext)
          .convertToInternalAttachment()
        return '<a' + anchorAttributes.toString() + '>'
      })
      return new FileContent(content)
    }
  }

  function AnchorAttributes (attributes) {
    this.attributes = attributes

    this.toString = function () {
      return this.attributes.join(' ')
    }

    this.convertToWcmLink = function (context) {
      var link = this._getLink()
      if (link !== null && !this._isExternalLink(link) && !this._isLinkAnchor(link) && this._isLinkXmlDocument(link)) {
        this._setLink(context + '?documentId=' + link)
      }
      return new AnchorAttributes(this.attributes)
    }

    this.convertToInternalAttachment = function () {
      var link = this._getLink()
      if (link !== null && !this._isExternalLink(link) && !this._isLinkAnchor(link) && !this._isLinkXmlDocument(link)) {
        this.attributes.push('ng-click="vm.download(\'' + link + '\')"')
        this._removeAttributeByName('href')
        this._removeAttributeByName('target')
      }
      return new AnchorAttributes(this.attributes)
    }

    this._getLink = function () {
      var link = null
      for (var i = 0, len = this.attributes.length; i < len; i++) {
        var matches = /href="(.*?)"/gmi.exec(this.attributes[i])
        if (matches) {
          link = matches[1]
          break
        }
      }
      return link
    }

    this._setLink = function (link) {
      this.attributes = this.attributes.map(function (attribute) {
        return attribute.replace(/href="(.*?)"/gmi, function (href) {
          return 'href="' + link + '"'
        })
      })
    }

    this._removeAttributeByName = function (attributeName) {
      this.attributes = this.attributes.filter(function (item) {
        var re = new RegExp('^' + attributeName, 'gmi')
        return !item.match(re)
      })
    }

    this._isExternalLink = function (link) {
      return link.match(/^http/gmi)
    }

    this._isLinkXmlDocument = function (link) {
      return link.match(/xml$/gmi)
    }

    this._isLinkAnchor = function (link) {
      return link.match(/^#/gmi)
    }
  }
}
