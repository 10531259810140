/* global angular */

var langCs = require('./i18n/cs.js').cs
var langEn = require('./i18n/en.js').en

angular.module('ExtBackendErrorCmp', [])
  .component('extBackendError', {
    template: require('./ext-backend-error.component.tpl.html'),
    controller: ExtBackendErrorCtrl,
    controllerAs: 'vm',
    bindings: {
      error: '<'
    }
  })

ExtBackendErrorCtrl.$inject = ['i18n']

function ExtBackendErrorCtrl (i18n) {
  var vm = this

  vm.$onInit = function () {
    i18n.appendLangFile('cs', langCs)
    i18n.appendLangFile('en', langEn)
  }
}
