/* global btoa */

var cookies = require('js-cookie')

adfsTokenService.$inject = ['appConfig', '$window', '$http']

function adfsTokenService (appConfig, $window, $http) {
  var cookieTokenName = 'p24Token'
  var extranet = window.location.href.indexOf('extranet') !== -1

  function endpoint (path) {
    return appConfig.adfs.baseUrl + path
  }

  function redirectToLogin () {
    var loginUrl = extranet ? appConfig.adfs.extranet : appConfig.adfs.partner24

    if (window.location.href.indexOf('gem.fat.erste-group.net') !== -1 || window.location.href.indexOf('gem.erste-group.net') !== -1) {
      return false
    } else {
      $window.location.replace(loginUrl)
    }
  }

  function saveToken () {
    var redirectFromAdfs = $window.location.hash.indexOf('access_token') !== -1 ? $window.location.hash.split('&') : null
    var token = redirectFromAdfs ? redirectFromAdfs.filter(function (item) {
      if (item.indexOf('access_token') !== -1) {
        return item
      }
    }) : null
    var clearToken = token ? token[0].split('=')[1] : null
    cleanUrl(redirectFromAdfs)
    if (clearToken) {
      setCookie(cookieTokenName, clearToken)
    }
  }

  function cleanUrl (adfsParams) {
    if (adfsParams && adfsParams.length) {
      $window.location.hash = ''
      $window.location.replace($window.location.href)
    }
  }

  function setCookie (name, value) {
    var hour = new Date(new Date().getTime() + 60 * 60 * 1000)
    var cookieOptions = {
      sameSite: 'lax',
      secure: true,
      expires: hour
    }
    cookies.set(name, 'Bearer ' + value, cookieOptions)
  }

  function removeCookie () {
    cookies.remove(cookieTokenName)
  }

  function getToken () {
    return cookies.get(cookieTokenName) || redirectToLogin()
  }

  function getBase64Token () {
    var accessToken = cookies.get(cookieTokenName).split(' ')[1]
    return btoa(encodeURIComponent(accessToken).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes (match, p1) {
        return String.fromCharCode('0x' + p1)
      }))
  }

  function revokeToken () {
    var logoutUrl = appConfig.adfs.logout
    var postLogoutUrl = extranet ? appConfig.adfs.extranet : appConfig.adfs.partner24
    logoutUrl = logoutUrl + '?post_logout_redirect_uri=' + postLogoutUrl
    if (cookies.get(cookieTokenName)) {
      $http({
        url: endpoint('revoke'),
        method: 'GET',
        params: {
          access_token: cookies.get(cookieTokenName).replace('Bearer ', '')
        },
        headers: {
          'WEB-API-key': undefined
        }
      })
        .then(function () {
          cookies.remove(cookieTokenName)
          $window.location.replace(logoutUrl)
        })
    } else {
      $window.location.replace(logoutUrl)
    }
  }

  function handle403Error (error) {
    if (error.status === 403) {
      redirectToLogin()
    }
  }

  saveToken()

  return {
    getToken: getToken,
    getBase64Token: getBase64Token,
    removeCookie: removeCookie,
    redirectToLogin: redirectToLogin,
    handle403Error: handle403Error,
    revokeToken: revokeToken
  }
}

module.exports = adfsTokenService
