/* global angular */

extContentService.$inject = ['$http', 'apiBase', '$q', 'adfsTokenService']

function extContentService ($http, apiBase, $q, adfsTokenService) {
  var endpointPath = apiBase + 'v2/cms'

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  function queryFile (wcmFileQueryPath, wcmFileNameLike) {
    var canceller = $q.defer()

    // Used twice same condition due wcmFileNameLike is optinal.
    var subConditions = [
      {
        fieldName: 'rFolderPath',
        value: wcmFileQueryPath,
        conditionType: 'EQUALS'
      },
      {
        fieldName: 'rFolderPath',
        value: wcmFileQueryPath,
        conditionType: 'EQUALS'
      }
    ]

    if (wcmFileNameLike) {
      subConditions.push(
        {
          fieldName: 'keywordValue',
          value: wcmFileNameLike,
          conditionType: 'LIKE_IGNORE_CASE'
        }
      )
    }

    var promise = $http({
      url: endpoint('contents/meta/search'),
      method: 'POST',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      data: {
        conditionType: 'AND',
        subConditions: subConditions
      },
      params: {
        pageSize: 1
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function queryFolderIcoFile (portalType, folderName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/meta/search'),
      method: 'POST',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      data: {
        conditionType: 'AND',
        subConditions: [
          {
            fieldName: 'rFolderPath',
            value: '/CS_IE/Systemy_a_aplikace/Extranet/' + portalType + '/Obrazky',
            conditionType: 'EQUALS'
          },
          {
            fieldName: 'keywordValue',
            value: folderName,
            conditionType: 'CONTAINS'
          },
          {
            fieldName: 'iFullFormat',
            value: 'ico',
            conditionType: 'EQUALS'
          }
        ]
      },
      params: {
        pageSize: 1
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getBlob (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getBlobByUrl (url) {
    var canceller = $q.defer()

    var promise = $http({
      url: url,
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getBlobUrl (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      cache: true,
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      transformResponse: appendTransform($http.defaults.transformResponse, toImageUrlTransformResponse)
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function appendTransform (defaults, transform) {
    defaults = angular.isArray(defaults) ? defaults : [defaults]
    return defaults.concat(transform)
  }

  function toImageUrlTransformResponse (blob, headerGetter) {
    var urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(blob)
  }

  function getHtml (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      cache: true,
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      params: {
        format: 'html'
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  return {
    queryFile: queryFile,
    queryFolderIcoFile: queryFolderIcoFile,
    getBlobUrl: getBlobUrl,
    getBlob: getBlob,
    getBlobByUrl: getBlobByUrl,
    getHtml: getHtml
  }
}

exports.extContentService = extContentService
