/* global angular */

require('../../services/ext-content.service.js')

angular.module('ExtWcmImageCmp', [])
  .component('extWcmImage', {
    template: require('./ext-wcm-image.component.tpl.html'),
    controller: ExtWcmImageCtrl,
    controllerAs: 'vm',
    bindings: {
      portal: '@',
      objectName: '@'
    }
  })

ExtWcmImageCtrl.$inject = ['extContentService']

function ExtWcmImageCtrl (extContentService) {
  var vm = this

  vm.$onInit = function () {
    vm.blobUrl = null
    extContentService.getBlobUrl(vm.portal, vm.objectName).promise
      .then(function (res) {
        vm.blobUrl = res.data
      })
  }

  vm.$onDestroy = function () {
    var urlCreator = window.URL || window.webkitURL
    urlCreator.revokeObjectURL(vm.blobUrl)
  }
}
