var inlet = require('aem-libs/src/initInlet.js').initInlet(
  'AemExtWcmHtmlContent',
  /* global WEBPACK_INLET_VERSION */ WEBPACK_INLET_VERSION
)
var angular = require('angular')
var adfsTokenService = require('aem-libs-angularjs/src/services/adfs-token.service.js')
var extContentService = require('aem-libs-angularjs/src/services/ext-content.service.js').extContentService
require('aem-libs-angularjs/src/components/ext-wcm-html-content/ext-wcm-html-content.component.js')
require('aem-libs-angularjs/src/components/ext-backend-error/ext-backend-error.component.js')

angular.module('AemExtWcmHtmlContent', ['ExtWcmHtmlContentCmp', 'ExtBackendErrorCmp'])
  .factory('adfsTokenService', adfsTokenService)
  .factory('extContentService', extContentService)

require('./inlet.css')
require('./inlet.controller.js')

require('aem-libs-angularjs/src/bootstrap').bootstrap({
  element: inlet.element,
  appName: 'AemExtWcmHtmlContent',
  mainCtrlName: 'aemExtWcmHtmlContentCtrl',
  basePath: inlet.basePath,
  config: /* global WEBPACK_INLET_CONFIG */ WEBPACK_INLET_CONFIG,
  supportedLanguages: {
    cs: require('./i18n/cs.js').cs,
    en: require('./i18n/en.js').en
  },
  html: require('./inlet.html')
})
