/* global angular */

var queryString = require('query-string')

angular.module('AemExtWcmHtmlContent')
  .controller('aemExtWcmHtmlContentCtrl', ctrl)

ctrl.$inject = ['wgService', 'adfsTokenService', 'extContentService', 'appLoader']

function ctrl (wgService, adfsTokenService, extContentService, appLoader) {
  var vm = this

  vm.$onInit = function () {
    vm.errorBackend = null
    vm.tokenReady = adfsTokenService.getToken()
    vm.fileContent = null
    vm.wcmHrefContext = wgService.getParams().wcmHrefContext
    var objectName = queryString.parse(window.location.search).documentId

    if (objectName) {
      var portal = 'internet'
      extContentService.getHtml(portal, objectName).promise
        .then(function (res) {
          vm.fileContent = res.data
        })
        .catch(function (err) {
          vm.errorBackend = err
        })
        .finally(function () {
          appLoader.hide()
        })
    } else {
      extContentService.queryFile(wgService.getParams().wcmFileQueryPath, wgService.getParams().wcmFileNameLike).promise
        .then(function (res) {
          if (!res.data.size) {
            throw new Error('WCM document not found')
          }
          return extContentService.getHtml(res.data.metadata[0].portal, res.data.metadata[0].objectName).promise
        })
        .then(function (res) {
          vm.fileContent = res.data
        })
        .catch(function (err) {
          vm.errorBackend = err
        })
        .finally(function () {
          appLoader.hide()
        })
    }
  }
}
